import React, { Component } from 'react';
import { User } from 'oidc-client';
import Splash from 'dashboard/Splash';
import Logout from 'auth/Logout';
import Header from 'header/Header';
import BookingHeader from 'header/BookingHeader';
import EventList from 'events/EventList';
import Event from 'events/Event';
import Contact from 'events/Contact';
import { Switch, Route, Redirect } from 'react-router-dom';
import Footer from 'footer/Bar';
import { getCompany } from 'actions/company';
import { connect } from 'react-redux';
import CompanyContext from 'company/CompanyContext';
import { logout } from 'auth/userManager';
import { ReduxState } from 'reducers/rootReducer';

type Props = ReduxProps & {
};

interface ReduxProps {
  user: User;
  catereaseCP: string;
  getCompany: Function;
}

interface State {
  company: any;
}

class Chrome extends Component<Props, State> {
  state = {
    company: {},
  };

  componentDidMount() {

    this.props.getCompany().then(company => {
      this.setState({ company });
    }).catch(err => {
      console.log('logging out due to...');
      console.log(err);
      //this.props.logout();
    });
  }

  fullAccess = () => {
    return (<Switch>
      <Route path="/contact" component={Contact} />
      <Route path="/booking/:bookingId" component={Event} />
      <Route path="/bookings" component={EventList} />
      <Route path="/tos" component={Logout} />
      <Route path="/" exact component={Splash} />
    </Switch>);
  }

  singleBooking = () => {
    const { user } = this.props;
    const bookingLookup = user ? user.profile['cover-BookingLookup'] : 0;

    return (<Switch>
      <Route path="/contact" component={Contact} />
      <Route path="/booking/:bookingId" component={Event} />
      <Route path="/tos" component={Logout} />
      <Redirect path="/bookings" to={`/booking/${bookingLookup}`} />
      <Redirect from="/" exact to={`/booking/${bookingLookup}`} />
    </Switch>);
  }

  render() {
    const { user, catereaseCP } = this.props;
    const {
      company,
    } = this.state;

    if (!company) {
      return null;
    }

    const hasFullAccess =
      (!!user && !user.profile['cover-BookingLookup'])
      && !catereaseCP;

    return (
      <CompanyContext.Provider
        value={this.state}
      >
        <header>
          <Switch>
            <Route path="/booking/:bookingId" component={BookingHeader} />
            <Route path="*" component={Header} />
          </Switch>
        </header>
        <main>
          {hasFullAccess ? this.fullAccess() : this.singleBooking()}
        </main>
        <footer>
          <Footer />
        </footer>
      </CompanyContext.Provider>
    );
  }
}

const mapDispatchToProps = {
  getCompany,
  logout,
};

const mapStateToProps = (state: ReduxState) => ({
  user: state.oidc.user,
  catereaseCP: state.caterease.catereaseCP,
});

export default connect(mapStateToProps, mapDispatchToProps)(Chrome);
