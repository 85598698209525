import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from 'components/Tabs';
import Files from 'components/Files';
import ChitChat from 'components/ChitChat/ChitChat';

const styles = theme => ({
  container: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  borderBottom: {
    borderBottom: `solid 1px ${theme.palette.grey[400]}`,
    marginBottom: 36,
  },
  tabContent: {
    display: 'flex',
    flex: '1 1 auto',
    width: '100%',
    minHeight: 350,
  },
  hr: {
    borderBottom: `solid 1px ${theme.palette.grey[400]}`,
    margin: '36px 0',
  },
});

const tabNames = ["Chit Chat", "Files"];

class BookingDetails extends Component {
  state = { selectedTab: 0 };

  tabChanged = newValue => {
    this.setState({ selectedTab: newValue });
  }

  render() {
    const { classes, bookingId } = this.props;
    const {
      selectedTab,
    } = this.state;

    return (
      <div className={classes.container}>
        <Tabs value={selectedTab} tabNames={tabNames} onChange={this.tabChanged} />
        <div className={classes.tabContent}>
          {selectedTab === 0 && <ChitChat bookingId={bookingId} />}
          {selectedTab === 1 && <Files bookingId={bookingId} />}
        </div>
        <div className={classes.hr}></div>
      </div >
    );
  }
}

export default withStyles(styles)(BookingDetails);
