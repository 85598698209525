import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReduxState } from 'reducers/rootReducer';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { submitRequest } from 'actions/company';
import { WebInquiry } from 'common/models';
import SimpleDialog from 'components/SimpleDialog';

const styles = (theme: Theme) => createStyles({
  contactContainer: {
    width: '100%',

  },
  contactUs: {
    display: 'flex',
    flexDirection: 'column',
    width: '65%',
    alignSelf: 'center',
    minHeight: 350,
    margin: 'Auto',
    padding: '36px 0',
  },
  header: {
    fontSize: 22,
    alignSelf: 'flex-start',
    marginBottom: 24,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignContent: 'space-around',
  },
  formRow: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    "@media (max-width: 480px)": {
      flexDirection: 'column',
    },
  },
  textField: {
    flex: '1 1 auto',
    marginLeft: 0,
    marginRight: 16,
    '&:last-child': {
      marginRight: 0,
    },
  },
});

type Props = WithStyles<typeof styles> & {
  submitRequest: Function;
};

type State = WebInquiry & {
};

const initialState: State = {
  name: '',
  email: '',
  website: '',
  comment: '',
};

class ContactUs extends Component<Props, State> {
  state = initialState;

  errorDialog;

  fieldOnChage = event => {
    const { target: { value, name } } = event;

    this.setState({
      ...this.state,
      [name]: value,
    });
  }

  submitRequest = event => {
    const { submitRequest } = this.props;

    submitRequest(this.state).then(result => {
      if (result.status === 200) {
        this.errorDialog.current.open('The request has been sent.');
      } else {
        this.errorDialog.current.open('An error has occurred during the request.');
      }

      this.setState({ ...initialState });
    })
      .catch(() => {
        this.errorDialog.current.open('An error has occurred during the request.');
      });
  }

  render() {
    const { classes } = this.props;
    const { name, email, website, comment } = this.state;

    this.errorDialog = React.createRef();

    return (
      <div className={classes.contactContainer}>
        <div className={classes.contactUs}>
          <div className={classes.header}>Contact Us</div>
          <div className={classes.form}>
            <div className={classes.formRow}>
              <TextField
                label="Name"
                name="name"
                value={name}
                onChange={this.fieldOnChage}
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Email"
                name="email"
                value={email}
                onChange={this.fieldOnChage}
                className={classes.textField}
                margin="normal"
                variant="outlined"
                type="email"
              />
              <TextField
                label="Website (Optional)"
                name="website"
                value={website}
                onChange={this.fieldOnChage}
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className={classes.formRow}>
              <TextField
                label="Comment"
                name="comment"
                value={comment}
                onChange={this.fieldOnChage}
                multiline
                rows="4"
                className={classes.textField}
                margin="normal"
                variant="outlined"
              />
            </div>
            <div className={classes.formRow}>
              <PrimaryButton
                onClick={this.submitRequest}
                disabled={!name || !email || !comment}
              >
                SUBMIT REQUEST
              </PrimaryButton>
            </div>

          </div>
        </div>
        <SimpleDialog onlyOkayButton={true} innerRef={this.errorDialog} />
      </div>
    );
  }
}

const mapDispatchToProps = {
  submitRequest,
};

const mapStateToProps = (state: ReduxState) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContactUs));
