import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { getMenu } from 'actions/menu';
import { getEventLocation } from 'actions/events';
import FullPageLoader from 'components/FullPageLoader';
import MenuSection from './MenuSection';
import EventCard from './EventCard';
import classNames from 'classnames';

const styles = theme => ({
  container: {
    borderTop: `solid 1px ${theme.palette.grey[400]}`,
    marginTop: 26,
    paddingTop: 26,
    display: 'flex',
    "@media (max-width: 425px)": {
      'flex-direction': 'column',
    },
  },
  label: {
    fontSize: 18,
    color: theme.palette.grey[900],
    paddingBottom: 20,
  },
  pad: {
    padding: '0 26px 26px 26px',
    "@media (max-width: 420px)": {
      padding: 0,
    },
  },
  rightColumn: {
    position: 'relative',  //for the loader
    padding: '0 0 26px 26px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    "@media (max-width: 420px)": {
      padding: 0,
    },
  },
  leftBorder: {
    borderLeft: `solid 1px ${theme.palette.grey[400]}`,
    paddingBottom: 26,
    flexGrow: 1,
  },
  padLeft: {
    paddingLeft: 26,
    "@media (max-width: 420px)": {
      padding: '0px',
    },
  },
  menuSections: {
    backgroundColor: theme.palette.background.paper,
  },
});

class MenuSections extends Component {
  state = {};

  async componentDidMount() {
    const {
      booking,
      getMenu,
      getEventLocation,
    } = this.props;

    this.setState({ selectedEventId: booking.events[0].id });

    for (let index = 0; index < booking.events.length; index++) {
      let event = booking.events[index];
      let menuDict = {};

      menuDict[`menu${event.id}`] = await getMenu(event.menuId); //using async so we don't spam out API... one request at a time
      this.setState(menuDict);

      let locationDict = {};

      locationDict[`location${event.id}`] = await getEventLocation(booking.id, event.id);
      this.setState(locationDict);
    }
  }

  async loadMenus() {

  }

  selectEvent = event => {
    this.setState({ selectedEventId: event.id });
  }

  render() {
    const {
      classes,
      booking: { events },
    } = this.props;
    const {
      selectedEventId,
    } = this.state;
    const menu = this.state[`menu${selectedEventId}`];

    return (
      <div className={classes.container}>
        <div className={classes.pad}>
          <div className={classes.label}>
            Events
          </div>
          {events.map(event =>
            <EventCard
              event={event}
              menu={this.state[`menu${event.id}`]}
              location={this.state[`location${event.id}`]}
              isFocused={selectedEventId === event.id}
              onSelectEvent={this.selectEvent}
            />
          )}
        </div>
        <div className={classes.rightColumn}>
          {menu ?
            <>
              <div className={classNames(classes.padLeft, classes.label)}>
                Menu
              </div>
              <div className={classNames(classes.padLeft, classes.leftBorder)}>
                <div className={classes.menuSections}>
                  {menu.menuSections.map((section, index) =>
                    <MenuSection
                      section={section}
                      key={section.id}
                      isLast={menu.menuSections.length === index + 1}
                    />
                  )}
                </div>
              </div>
            </>
            :
            <FullPageLoader height="100%" message="loading..." />
          }
        </div>
      </div>
    );

  }
}
const mapDispatchToProps = {
  getMenu,
  getEventLocation,
};

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(MenuSections));
