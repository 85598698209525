import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

const styles = theme => ({
  eventCard: {
    width: 226,
    height: 170,
    boxSizing: 'border-box',
    color: theme.palette.blue.blue6,
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 4,
    fontSize: 13,
    marginBottom: 16,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: 186,
  },
  moveUp: {
    position: 'relative',
    top: -1,
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    height: 96,
    paddingBottom: 2,
    justifyContent: 'space-between',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  topHeader: {
    paddingTop: 13,
  },
  grey: {
    color: theme.palette.grey[500],
  },
  biggerFont: {
    fontSize: 15,
  },
  label: {
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.grey[500]}`,
    height: 70,
  },
  bottomData: {
    flexGrow: 1,
  },
  focused: {
    borderColor: theme.palette.blue.blue1,
    borderWidth: 2,
  },
  unincluded: {
    borderColor: theme.palette.grey[100],
    background: theme.palette.grey[50],
    color: theme.palette.grey[500],
  },
});

class EventCard extends Component {
  state = {
  };

  onSelectEvent = () => {
    const {
      onSelectEvent,
      event,
    } = this.props;

    onSelectEvent && onSelectEvent(event);
  }

  render() {
    const {
      classes,
      event,
      isFocused,
      menu = {},
      location = {},
    } = this.props;

    const startTime = DateTime.fromISO(event.startTime);
    const endTime = DateTime.fromISO(event.endTime);
    const totalValue = menu.totalValue ? parseInt(menu.totalValue) : 0;
    const itemCount = menu.itemCount || 0;
    const locationName = location.name || 'none';

    return (
      <div
        className={classNames(classes.eventCard,
          isFocused && classes.focused,
          event.isIncludedInBookingTotal === false && classes.unincluded)}
        onClick={this.onSelectEvent}
      >
        <div className={classNames(classes.content, isFocused && classes.moveUp)}>
          <div className={classes.top}>
            <div className={classes.topHeader}>
              <div className={classNames(classes.biggerFont, classes.label)}>{event.name}</div>
              <div className={classNames(classes.grey, classes.label)}>{startTime.toFormat('M/d/yyyy h:mma - ')}{endTime.toFormat('h:mma')}</div>
            </div>
            <div className={classNames(classes.flex, classes.biggerFont)}>
              <span className={classes.label}>{locationName}</span>
              <span className={classes.label}>{event.estimatedGuestCount} Guests</span>
            </div>
          </div>
          <div className={classes.bottom}>
            <div className={classes.bottomData}>
              <div className={classes.grey}>VALUE</div>
              <div>${totalValue}</div>
            </div>

            <div className={classes.bottomData}>
              <div className={classes.grey}>ITEMS</div>
              <div>{itemCount}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EventCard.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  menu: PropTypes.object,
};

export default withStyles(styles)(EventCard);
