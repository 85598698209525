import React, { Component } from 'react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ContactUs from 'components/ContactUs';
import { ReduxState } from 'reducers/rootReducer';
import CompanyInfo from 'components/CompanyInfo';

const styles = (theme: Theme) => createStyles({
  content: {
    display: 'flex',
    flexFlow: 'column',
    backgroundColor: theme.palette.grey[200],
  },
});

type Props = WithStyles<typeof styles> & {
};

class Event extends Component<Props> {

  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.content}>
        <CompanyInfo />
        <ContactUs />
      </div>
    );
  }
}

const mapDispatchToProps = {
};

const mapStateToProps = (state: ReduxState) => {
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Event));
