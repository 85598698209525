import React, { useContext } from 'react';
import CompanyContext from 'company/CompanyContext';

export default ({ fontSize = 70 }) => {
  const {
    company,
  } = useContext(CompanyContext);

  return (
    <div style={{ fontSize: fontSize, textAlign: 'center', marginTop: '-2px' }}>
      {company.name}
    </div>);
};
