import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { fetchChitChat, saveNewChitChatThread } from 'actions/chitchat';
import moment from 'moment';
import Thread from 'components/ChitChat/Thread';
import NewPostBox from 'components/ChitChat/NewPostBox';
import SimpleDialog from 'components/SimpleDialog';
import { CHIT_CHAT_RELATIONSHIPS, CHIT_CHAT_TYPES } from 'common/chitChatTypes';

const styles = theme => ({
  chitChat: {
    display: 'flex',
    flexDirection: 'column',
    width: 'inherit',
    flex: '1 1 auto',
  },
  postsContainer: {
    flexGrow: 1,
  },
  timelineHeader: {
    flexGrow: 1,
    height: 33,
    lineHeight: '33px',
    verticalAlign: 'middle',
    paddingLeft: 33,
    fontSize: 17,
  },
  filterTimeline: {
    width: '100%',
    fontSize: 16,
    verticalAlign: 'middle',
    padding: '6px 8px 8px 33px',
  },
  messagesSection: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 150,
    height: '100%',
    padding: '0 33px 8px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
});

class ChitChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPost: {},
      isPollingExpired: false,
    };

    this.chitChatRelationship = CHIT_CHAT_RELATIONSHIPS.booking;
    this.errorDialog = React.createRef();
  }

  componentDidMount() {
    this.startPolling();
  }

  componentWillUnmount() {
    this.stopPolling();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookingId !== this.props.bookingId) {
      this.refreshChitChat();
    }
  }

  pollForNewMessages = () => {
    const { isPollingExpired } = this.state;
    const refreshInterval = window.env.REACT_APP_CHITCHAT_REFRESH_INTERVAL || 10000;

    if (!isPollingExpired) {
      console.log('Polling... (every ', refreshInterval, 'ms)');
      this.refreshChitChat();
      setTimeout(this.pollForNewMessages, refreshInterval);
    }
  };

  startPolling = () => {
    const idleLimit = 10800000; // 3 hours

    this.pollForNewMessages();
    setTimeout(this.stopPolling, idleLimit);
  }

  stopPolling = () => {
    console.log('STOP polling');
    this.pollForNewMessages = null;
    this.setState({ isPollingExpired: true });
  }

  refreshChitChat = () => {
    const { fetchChitChat, bookingId } = this.props;

    fetchChitChat(bookingId);
  }

  saveNewPost = () => {
    const { newPost } = this.state;
    const { bookingId, saveNewChitChatThread } = this.props;

    const post = {
      baseMessage: {
        subject: 'Post',
        content: newPost.message,
        time: moment().format(),
      },
      relationshipId: bookingId,
      chitChatRelationship: this.chitChatRelationship,
    };

    return saveNewChitChatThread(CHIT_CHAT_TYPES.post, post)
      .then(this.refreshChitChat)
      .catch(() => this.errorDialog.current.open('Something went wrong. Please try again.'));
  }

  handleChange = event => {
    const { target: { value, name } } = event;

    const newPost = {
      ...this.state.newPost,
      [name]: value,
    };

    this.setState({ newPost });
  }

  onAdd = () => {
    const { newPost } = this.state;

    if (!!newPost.message) {
      this.saveNewPost()
        .then(this.setState({ newPost: {} }))
        .catch(() => this.errorDialog.current.open('Something went wrong. Please try again.'));
    } else {
      return this.errorDialog.current.open('Please include a message.');
    }
  }

  render() {
    const { classes, threads } = this.props;
    const { newPost } = this.state;

    return (
      <div className={classes.chitChat}>
        <div className={classes.postsContainer}>
          <NewPostBox
            newPost={newPost}
            handleChange={this.handleChange}
            onAdd={this.onAdd}
          />
          <div className={classes.timelineHeader}>
            Posts
          </div>
        </div>

        <div className={classes.messagesSection}>
          {threads && threads.map(thread =>
            <Thread
              thread={thread}
              key={thread.id}
              onCommentSent={this.refreshChitChat}
              chitChatRelationship={this.chitChatRelationship}
            />)
          }
        </div>
        <SimpleDialog onlyOkayButton={true} innerRef={this.errorDialog} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    api: {
      chitChat: {
        threads,
      },
    },
  } = state;

  return {
    threads,
  };
};

const mapDispatchToProps = {
  fetchChitChat,
  saveNewChitChatThread,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChitChat));
