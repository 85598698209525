import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Tabs as MaterialTabs,
  Tab,
} from '@material-ui/core';

const styles = theme => ({
  tabStyle: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    borderBottomColor: theme.palette.grey[300],
    minWidth: 'unset',
    textTransform: 'unset',
  },
  indicator: {
    backgroundColor: theme.palette.blue.blue4,
  },
});

class Tabs extends Component {
  handleChange = (_event, newValue) => {
    this.props.onChange(newValue);
  }

  render() {
    const {
      classes,
      value,
      tabNames,
    } = this.props;

    return (
      <MaterialTabs value={value} onChange={this.handleChange} classes={{ indicator: classes.indicator }}>
        {tabNames.map(name => <Tab label={name} className={classes.tabStyle} key={name} />)}
      </MaterialTabs>
    );
  }
}

export default withStyles(styles)(Tabs);
